import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/buildhome/repo/src/components/blog/blogs-details.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`Today we released functionality to archive pets. There are a lot of reasons why someone may want to archive their pet’s information, but unfortunately most of them are not happy events. Historically, the only option Luna Journal had was to delete the pet entirely, which would cause you to lose access to the data you’ve kept for that pet. We understand that if you’ve experienced the loss of a pet recently, it can be difficult to see their name or information when you’re trying to do other things. Archived pets helps with this issue.`}</p>
    <p><img parentName="p" {...{
        "src": "../../assets/image/posts/archived_pet_screenshot.png",
        "alt": "Archiving a Pet in Luna Journal"
      }}></img></p>
    <p>{`If you’ve reached a point where you archive a pet, here’s what happens in Luna Journal.`}</p>
    <ul>
      <li parentName="ul">{`We unschedule all future notifications related to that pet.`}</li>
      <li parentName="ul">{`We hide them as an option while using the rest of the app.`}</li>
    </ul>
    <p>{`This means that you won’t see the archived pet while using the app, with one exception. We want you to be able to unarchive you pet, too. If you want to look back on memories you shared or are interested in sharing your data to contribute to animal research (or really, for any reason!), we want to make sure thats a possibility for you. You can unarchive your pets on the manage pet screen, which will add them back to rest of the app.`}</p>
    <p>{`We’ll be looking to grow the archive and delete functionality in the future. If you have any suggestions on what you’d like to see next, we’d love it if you left us a comment below.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      